export default class RoleBaseline
{
    _id = '';

    name = 'New Role Baseline';

    dashboard = '';

    department: string | null = null;

    function: string | null = null;

    jobCode: string | null = null;

    location: string | null = null;

    yearRange: [number, number] = [0, 30];

    updated: Date = new Date();
}
