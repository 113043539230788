import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';
import { Dashboard } from '@/models/hcad/shared/dashboard';

@Module
class RoleBaselineModule extends VuexModule
{
    @Action
    async getRoleBaselineById(id: string)
    {
        try
        {
            const resp = await axios.get<RoleBaseline>(`/rolebaselines/${id}`);
            return Object.assign(new RoleBaseline, resp.data);
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllRoleBaselinesInDashboard(dashboardId: string)
    {
        try
        {
            const resp =  await axios.get<RoleBaseline[]>(`/rolebaselines/dashboard/${dashboardId}`);
            for(let i = 0; i < resp.data.length; ++i)
            {
                resp.data[i] = Object.assign(new Dashboard, resp.data[i]);
            }
            return resp.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async createRoleBaseline(args: { dashboardId: string, baseline: RoleBaseline })
    {
        const { dashboardId, baseline } = args;
        try
        {
            const res =  await axios.post<RoleBaseline>(`/rolebaselines/${dashboardId}`, { baseline });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async updateRoleBaseline(baseline: RoleBaseline)
    {
        try
        {
            const res =  await axios.patch<RoleBaseline>(`/rolebaselines/${baseline._id}`, { baseline: baseline });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async deleteRoleBaseline(id: string)
    {
        try
        {
            await axios.delete(`/rolebaselines/${id}`);
            return true;
        }
        catch(err)
        {
            console.error(err);
        }
        return false;
    }
}

// Register the module
import store from '../index';
import RoleBaseline from '@/models/hcad/shared/role-baseline';
const roleBaselineModule = new RoleBaselineModule({store, name: 'roleBaseline'});
export default roleBaselineModule;