




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerSorter } from '../../../utils/typed-configs';

@Component
export default class TimeInOrgRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: number;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get displayTime()
    {
        const v = this.value;
        if (v < 1)
        {
            return `${Math.floor(v * 12)} months`;
        }
        return `${Math.floor(v)} years`;
    }
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.TimeInOrganization, TimeInOrgRenderer)
    .registerDataFactory(DashboardPageFieldType.TimeInOrganization, ()=>
        (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter)=>
        {
            // just go with it here...
            const aval = a[0][fieldIdx][1] as number;
            const bval = b[0][fieldIdx][1] as number;
            return (aval) - (bval);
        });

