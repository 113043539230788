import {
    BaseDashboardPage, 
    BaseDashboardPageField,
    BaseDashboardPageFilter, 
    BaseFilterQueryData, 
    DashboardPageFieldType,
    DashboardPageFilterType, 
    DashboardPageType, 
    DataSourceConfig,
    FieldValueType,
    IndividualDataTableUserData
} from "@/models/hcad/shared/dashboard";
import { UserInfoAndMetrics } from "@/models/hcad/shared/queries";

import { VueConstructor } from "vue";


type TypedConfigFactory<T> = () => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class TypedConfigSet<KeyT = any, DataT = any>
{
    getComponent(key: KeyT)
    {
        return this.components.get(key);
    }
    
    getDataFactory(key: KeyT)
    {
        return this.factories.get(key);
    }

    registerComponent(key: KeyT, component: VueConstructor<Vue>)
    {
        this.components.set(key, component);
        return this;
    }

    registerDataFactory(key: KeyT, factory: TypedConfigFactory<DataT>)
    {
        this.factories.set(key, factory);
        return this;
    }

    category<KT, DT>(name: string)
    {
        if (!this.categories.has(name))
        {
            this.categories.set(name, new TypedConfigSet<KT, DT>());
        }
        return this.categories.get(name) as TypedConfigSet<KT, DT>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private factories = new Map<KeyT, TypedConfigFactory<DataT>>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private components = new Map<KeyT, VueConstructor<Vue>>();
    private categories = new Map<string, TypedConfigSet>();
}

const TypedComponentConfigs = new TypedConfigSet;
export default TypedComponentConfigs;

const dataSourceRoot = TypedComponentConfigs.category('datasource');
export const dataSourceEditComponents = dataSourceRoot.category<string, DataSourceConfig>('editors');

const pageRoot = TypedComponentConfigs.category('pages');
export const pageEditorComponents = pageRoot.category<DashboardPageType, BaseDashboardPage>('editors');
export const pageViewerComponents = pageRoot.category<DashboardPageType, BaseDashboardPage>('viewers');

const filterRoot = TypedComponentConfigs.category('filters');
export const filterEditorComponents = filterRoot.category<DashboardPageFilterType, BaseDashboardPageFilter>('editors');
export const filterRuntimeComponents = filterRoot.category<DashboardPageFilterType, BaseFilterQueryData>('viewers');

const fieldRoot = TypedComponentConfigs.category('fields');
export const fieldEditorComponents = fieldRoot.category<DashboardPageFieldType, BaseDashboardPageField>('editors');

// The data factory for fieldViewerComponents returns a sort function
export type FieldViewerSorter = [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>];
export type FieldSortFn = (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter) => number;
export const fieldViewerComponents = fieldRoot.category<DashboardPageFieldType, FieldSortFn>('viewers');