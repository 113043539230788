













import { Component, Vue, Prop } from 'vue-property-decorator';
import { dataSourceEditComponents } from '@/utils/typed-configs';
import validationRules from '@/utils/validation-rules';
import { validateRefs } from '@/utils/validate-refs';
import { BladeDataSourceConfig, Dashboard, DataSourceTypes } from '@/models/hcad/shared/dashboard';

@Component
export default class BladeDataSourceConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    config!: BladeDataSourceConfig;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    rules = validationRules;

    mounted()
    {
        // Ensure that at least the root object is properly initialized
        this.dashboard.dataSourceConfigs.blade =
            Object.assign(new BladeDataSourceConfig(), this.dashboard.dataSourceConfigs.blade) as BladeDataSourceConfig;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}

dataSourceEditComponents
    .registerComponent(DataSourceTypes.Blade, BladeDataSourceConfigEdit)
    .registerDataFactory(DataSourceTypes.Blade, ()=>new BladeDataSourceConfig)
;
