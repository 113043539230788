import getParameterByName from '@/utils/query-params';
import axios from '@/utils/axios';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import User from '@/models/User';

const authTokenKey = 'hcadAuthToken';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/
@Module
class AuthModule extends VuexModule
{
    currentInitPromise: Promise<unknown> | null = null;
    hasInitialized = false;
    activeUser: User | null = null;
    authToken: string | null = null;

    get isSignedIn()
    {
        return this.activeUser != null;
    }

    get isHcadAdmin()
    {
        if (!this.activeUser) return false;
        
        return hasAnyRoles(this.activeUser, ['owner', 'developer']);
    }

    @Mutation
    private setInitialized(init: boolean)
    {
        this.hasInitialized = init;
    }

    @Mutation
    setActiveUser(user: User | null)
    {
        this.activeUser = user;
    }

    @Mutation
    setAuthToken(token: string | null)
    {
        if (token === 'invalid')
        {
            this.authToken = null;
        }
        else
        {
            this.authToken = token;
        }

        if (this.authToken)
        {
            localStorage.setItem(authTokenKey, this.authToken);
        }
        else 
        {
            localStorage.removeItem(authTokenKey);
        }
    }

    @Mutation
    private setInitPromise(init: Promise<unknown> | null)
    {
        this.currentInitPromise = init;
    }

    @Action
    async init()
    {
        // This looks convoluted, but it's not that bad.  Basically this allows auth.init to be called
        // from multiple places, but only actually make the http calls once and synchronize the results.
        while (this.currentInitPromise != null)
        {
            await this.currentInitPromise;
        }

        if (this.hasInitialized) return;

        let loaded;
        const param = getParameterByName('token');
        if (param)
        {
            loaded = param;
        }
        else
        {
            loaded = localStorage.getItem(authTokenKey);
        }
        
        if (loaded)
        {
            this.setAuthToken(loaded);
            try
            {
                const promise = axios.post<{ user: User }>('users/me');

                this.setInitPromise(promise);
                const resp = await promise;
                this.setActiveUser(resp.data.user);
            }
            catch(err)
            {
                console.error(err);
                this.setAuthToken(null);
                return;
            }
            finally
            {
                this.setInitPromise(null);
                this.setInitialized(true);
            }
        }
    }

    @Action
    async login(form: { email: string; password: string })
    {
        const resp = await axios.post<{ user: User; token: string }>('users/login', form);
        this.setActiveUser(resp.data.user);
        this.setAuthToken(resp.data.token);
        return resp.data;
    }

    @Action
    async logout()
    {
        this.setActiveUser(null);
        this.setAuthToken(null);
        window.location.reload();
    }
}

// Register the module
import store from '../index';
import { hasAnyRoles } from '@/utils/role-util';
const authModule = new AuthModule({store, name: 'auth'});
export default authModule;