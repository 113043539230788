
























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Dashboard } from '../../../models/hcad/shared/dashboard';
import dashModule from '../../../store/modules/DashboardModule';
import DataSourceConfigList from './edit-components/DataSourceConfigList.vue';
import CapabilityConfigList from './edit-components/CapabilityConfigList.vue';
import PageList from './edit-components/PageList.vue';
import { beginRefValidation, endRefValidation, validateRefs } from '@/utils/validate-refs';
import AssetSelectInput from '@/components/AssetSelectInput.vue';
import copy from 'copy-to-clipboard';

function computeEmailListFromString(list: string)
{
    if (!list || list.length === 0) return [];

    const separated = list.split(',');
    if (separated.length === 0) return [];
    for(let i = 0; i < separated.length; i++)
    {
        separated[i] = separated[i].trim();
    }
    return separated;
}

@Component({components: {DataSourceConfigList, CapabilityConfigList, PageList, AssetSelectInput}})
export default class DashboardEdit extends Vue
{
    @Prop({type: String, default: null})
    targetId!: string | null;

    get emailRecipientList()
    {
        if (!this.target || !this.target.environmentSettings.emailDomain) return '';

        let list = computeEmailListFromString(this.target.environmentSettings.emailDomain.trim());
        list = list.map(a => `"${a}"`);
        return list.join(', ');
    }

    startName = '';

    importSrc = '';
    showingImportDialog = false;
    importJsonFromDialog()
    {
        try
        {
            if (this.importFromJson(this.importSrc))
            {
                this.showingImportDialog = false;
                this.importSrc = '';
            }
        }
        catch(err)
        {
            console.error(err);
        }
    }

    openImportDialog()
    {
        this.importSrc = '';
        this.showingImportDialog = true;
    }

    loading = false;
    targetNullable: Dashboard | null = null;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true
    };

    copyJsonToClipboard()
    {
        const temp = this.target;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (temp._id) delete (temp as any)._id;
        copy(JSON.stringify(temp));

        alert('Dashboard data copied into clipboard.  You can now paste it into the "Import" dialog in another environment.');
    }

    importFromJson(json: string)
    {
        const dash = JSON.parse(json) as Dashboard;
        dash._id = this.target._id;
        
        // Make sure it looks like a dashboard object
        if (dash.name && dash.environmentSettings && dash.dataSourceConfigs && dash.capabilities)
        {
            this.$set(this, 'targetNullable', dash);
        }
        else
        {
            alert('Invalid JSON: Required fields are missing.  Please try copying the dashboard again.');
            return false;
        }
        return true;
    }

    get skeletonType()
    {
        return ['card-heading', 'card'].join(',');
    }

    get target()
    {
        return this.targetNullable as Dashboard;
    }

    enableHoverForAll()
    {
        this.target.capabilities.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                cap.enableHoverText = true;
                if (cap.subCapabilities)
                {
                    cap.subCapabilities.forEach((subcap) =>
                    {
                        subcap.enableHoverText = true;
                    });
                }
            });
        });
    }

    disableHoverForAll()
    {
        this.target.capabilities.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                cap.enableHoverText = false;
                cap.hoverText = '';
                if (cap.subCapabilities)
                {
                    cap.subCapabilities.forEach((subcap) =>
                    {
                        subcap.enableHoverText = false;
                        subcap.hoverText = '';
                    });
                }
            });
        });
    }

    enableFilterHoverForAll()
    {
        this.target.capabilities.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                cap.enableFilterHoverText = true;
                if (cap.subCapabilities)
                {
                    cap.subCapabilities.forEach((subcap) =>
                    {
                        subcap.enableFilterHoverText = true;
                    });
                }
            });
        });
    }

    disableFilterHoverForAll()
    {
        this.target.capabilities.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                cap.enableFilterHoverText = false;
                cap.filterHoverText = '';
                if (cap.subCapabilities)
                {
                    cap.subCapabilities.forEach((subcap) =>
                    {
                        subcap.enableFilterHoverText = false;
                        subcap.filterHoverText = '';
                    });
                }
            });
        });
    }

    validate()
    {
        try
        {
            beginRefValidation();
            return validateRefs(this.$refs);
        }
        finally
        {
            endRefValidation();
        }
    }

    copyIntoNew()
    {
        alert('Copying dashboard.  Please note that it will not be fully created until you click save.');
        this.$router.push('/admin/dashboard/new');
    }

    async submit()
    {
        if (!this.targetNullable || !this.validate()) return;
        
        if (this.isNew)
        {
            const resp = await dashModule.createDashboard(this.target);
            if (resp)
            {
                this.$router.replace(`/admin/dashboard/edit/${resp._id}`);
                alert('Dashboard saved');
            }
            else
            {
                alert('Failed to create dashboard');
            }
        }
        else
        {
            const resp = await dashModule.updateDashboard(this.target);
            if (resp)
            {
                alert('Dashboard saved');
            }
            else
            {
                alert('Failed to update dashboard');
            }
        }
    }

    async refresh()
    {
        this.loading = true;
        if (this.isNew)
        {
            this.targetNullable = new Dashboard();
        }
        else
        {
            this.targetNullable = await dashModule.getDashboardById({ id: this.targetId as string });
            if (this.targetNullable)
            {
                this.startName = this.targetNullable.name;
            }
        }
        this.loading = false;
    }

    async mounted()
    {
        await this.refresh();
    }

    get isNew()
    {
        return this.targetId == null;
    }
}
