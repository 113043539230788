




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    BaseDashboardPageFilter,
    DashboardPageFilterConfig,
    BaseFilterQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';

// TODO: Automate this...?
require('./FilterRange.vue');
require('./FilterSelect.vue');
require('./FilterRoleBaseline.vue');
require('./MetricsToggle.vue');

@Component({})
export default class FilterSidebar extends Vue
{
    @Prop({ type: Object, required: true })
    filterConfig!: DashboardPageFilterConfig;

    @Prop({ type: Array, required: true })
    value!: BaseFilterQueryData[];

    hasLoadedValues = false;

    @Watch('filterConfig.filters')
    onNewFilters(newValue: BaseDashboardPageFilter[])
    {
        this.initFilterState(newValue);
    }

    mounted()
    {
        if (this.filterConfig && this.filterConfig.filters)
        {
            this.initFilterState(this.filterConfig.filters);
        }
    }

    initFilterState(newValue: BaseDashboardPageFilter[])
    {
        this.hasLoadedValues = true;
        if (newValue && newValue.length)
        {
            this.value.length = 0;
            newValue.forEach((f) =>
            {
                const factory = filterRuntimeComponents.getDataFactory(f.type);
                if (factory)
                {
                    this.value.push(factory());
                }
                else
                {
                    console.error(`Error: No factory for filter type ${DashboardPageFilterType[f.type]}. Please notify the developers of this error.`);
                }
            });
        }
    }

    viewerComponent(filter: BaseDashboardPageFilter)
    {
        return filterRuntimeComponents.getComponent(filter.type);
    }

    errorString(filter: BaseDashboardPageFilter)
    {
        return `No page registered for type ${this.typeString(filter)}. Please notify the developers of this error.`;
    }

    typeString(filter: BaseDashboardPageFilter)
    {
        return DashboardPageFilterType[filter.type];
    }
}
