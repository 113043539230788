




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerSorter } from '../../../utils/typed-configs';

@Component
export default class MatchPercentRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get percentDisplay()
    {
        const v = this.value as number;
        return Math.round(v * 100);    
    }

    get computedColor()
    {
        if (this.percentDisplay >= 90) return 'green';
        if (this.percentDisplay >= 80) return '#FF9E0D';
        if (this.percentDisplay >= 70) return 'orange';
        return 'red';
    }
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.MatchPercent, MatchPercentRenderer)
    .registerDataFactory(DashboardPageFieldType.MatchPercent, ()=>
        (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter)=>
        {
            // just go with it here...
            const aval = a[0][fieldIdx][1] as number;
            const bval = b[0][fieldIdx][1] as number;
            return (aval) - (bval);
        });

