










import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageMetricsToggleFilter, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { filterEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';

@Component
export default class MetricsToggleConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageMetricsToggleFilter;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    validate()
    {
        return validateRefs(this.$refs);
    }
}

filterEditorComponents
    .registerComponent(DashboardPageFilterType.MetricsToggle, MetricsToggleConfigEdit)
    .registerDataFactory(DashboardPageFilterType.MetricsToggle,
        ()=>new DashboardPageMetricsToggleFilter(DashboardPageFilterType.MetricsToggle, true, 'Capabilities'))
;
