import {
    BenchmarkData,
    BenchmarkQueryData,
    Dashboard,
    DashboardPageType,
    IndividualDataTableUserData,
} from '@/models/hcad/shared/dashboard';
import {
    DashboardDataResult,
    QueryContextViewerQuery,
    UserInfoAndMetrics,
    FilterValueType,
} from '@/models/hcad/shared/queries';
import axios from '@/utils/axios';
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
// import { BaseDashboardPage, Dashboard } from '@/models/hcad/shared/dashboard';

@Module
class DataSourceModule extends VuexModule
{
    dataSourceTypes: string[] | null = null;

    @Mutation
    private setDataSourceTypes(types: string[])
    {
        this.dataSourceTypes = types;
    }

    @Action
    async getDataSourceTypes(forceReload = false)
    {
        if (!forceReload && this.dataSourceTypes) return this.dataSourceTypes;
        try
        {
            const res = await axios.get<string[]>('datasources/types');
            const types = res.data;
            this.setDataSourceTypes(types);
            return types;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async queryPageData<T>(request: { dashboard: Dashboard; pageIdx: number; filterValues: FilterValueType[]; pageData?: any })
    {
        const { dashboard, pageIdx, filterValues, pageData } = request;
        try
        {
            const query = new QueryContextViewerQuery();
            query.filterValues = filterValues;
            query.pageData = pageData;
            
            const val = await axios.post<DashboardDataResult<T>>(`datasources/query/${dashboard._id}/${pageIdx}`, { query });
            return val.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async queryIndividualUserTablePage(request: { dashboard: Dashboard; pageIdx: number; filterValues: FilterValueType[] })
    {
        if (request.dashboard.pages[request.pageIdx].type != DashboardPageType.IndividualDataTable)
        {
            throw new Error('Attempted to query non-individual user table page as individual user table page');
        }
        return await this.queryPageData<UserInfoAndMetrics<IndividualDataTableUserData>[]>(request);
    }

    @Action
    async queryBenchmarkPage(request: { dashboard: Dashboard; pageIdx: number; filterValues: FilterValueType[]; pageData: BenchmarkQueryData })
    {
        if (request.dashboard.pages[request.pageIdx].type != DashboardPageType.Benchmark)
        {
            throw new Error('Attempted to query non-benchmark page as benchmark page');
        }
        return await this.queryPageData<BenchmarkData[]>(request);
    }
}

// Register the module
import store from '../index';
const dataSourceModule = new DataSourceModule({store, name: 'dataSource'});
export default dataSourceModule;