










































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CapabilityCategory, Capability } from '@/models/hcad/shared/dashboard';
import DynamicIcon from '@/components/DynamicIcon.vue';

import { displayLength } from '@/utils/text-util';

@Component({
    components: {
        DynamicIcon,
    }
})
export default class MetricsGroup extends Vue
{
    @Prop({ default: new CapabilityCategory() }) readonly category!: CapabilityCategory;
    @Prop(Boolean) readonly forceTwoLineTitle!: boolean;

    get metrics()
    {
        return this.category.capabilities;
    }

    groupCount()
    {
        return ;
    }

    groupHeight()
    {
        return Math.max(
            (35 * this.metrics.length)        // top-level capability button height
            + (4 * (this.metrics.length - 1)) // top-level button margin
            + (25 * this.metrics              // visible sub-capability button height
                .filter(m => m.expanded)
                .map(m => m.subCapabilities.length)
                .reduce((acc, v) => acc + v, 0)),
            (this.category.name.length > 7 ? 100 : 74) // allow for long category names with 1 or 2 capabilities
        );
    }

    get shouldWrapTitle()
    {
        const twoLineCharLimit = 17.95 + Math.max(0, (this.metrics.length - 3) * 5.9);
        return displayLength(this.category.name.toUpperCase()) > twoLineCharLimit;
    }

    get twoLineTitle()
    {
        return this.forceTwoLineTitle || this.shouldWrapTitle;
    }

    async expandSubcapabilities(ev: Event, m: Capability)
    {
        ev.stopPropagation();
        ev.preventDefault();
        ev.stopImmediatePropagation();
        m.expanded = !(m.expanded);
        this.$forceUpdate();
        await this.$nextTick();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mgroup as any).focus();
        await this.$nextTick();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mgroup as any).blur();
    }

    selectCapability(m: Capability)
    {
        m.selected = !(m.selected);
        this.$forceUpdate();
        this.$emit('input', m);
    }
}
