


























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseDashboardPage, DashboardPageType, Dashboard } from '../../../../models/hcad/shared/dashboard';
import { pageEditorComponents } from '../../../../utils/typed-configs';
import { validateRefs } from '../../../../utils/validate-refs';
import FilterList from './FilterList.vue';

// TODO: Automate this...?
require('./page-configurators/BenchmarkPageConfigEdit.vue');
require('./page-configurators/IndividualPageConfigEdit.vue');
require('./page-configurators/RoleBaselinePageConfigEdit.vue');

@Component({components: {FilterList}})
export default class PageEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: BaseDashboardPage;

    @Prop({type: Object, required: false})
    dashboard!: Dashboard | null;

    get pageTypeOptions()
    {
        return [
            {
                text: this.typeString,
                value: null
            }
        ];
    }

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    get typeString()
    {
        return DashboardPageType[this.page.type];
    }

    get editorComponent()
    {
        return pageEditorComponents.getComponent(this.page.type);
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
