const environments = {
    development: {
        API: process.env.VUE_APP_DEVELOPMENT_API_BASEURL || 'http://localhost:5000/api',
        DefaultTabText: 'B.L.A.D.E. Dashboards'
    },
    almas: {
        API: process.env.VUE_APP_API_BASEURL || 'https://hcadapi.production.almas.redmeru.com/api',
        DefaultTabText: 'ALMAS INSIGHT'
    },
    corpstaging: {
        API: process.env.VUE_APP_API_BASEURL || 'https://hcadapi.staging.corporate.redmeru.com/api',
        DefaultTabText: 'B.L.A.D.E. Dashboards'
    },
    experimental: {
        API: process.env.VUE_APP_API_BASEURL || 'https://hcadapi.experimental.redmeru.com/api',
        DefaultTabText: 'B.L.A.D.E. Dashboards'
    },
    deloitte: {
        API: process.env.VUE_APP_API_BASEURL || 'https://hcadapi.production.deloitte.redmeru.com/api',
        DefaultTabText: 'DELOITTE DASHBOARD'
    },
    aus: {
        API: process.env.VUE_APP_API_BASEURL || 'https://hcadapi.staging.aus.redmeru.com/api',
        DefaultTabText: 'B.L.A.D.E. Dashboards'
    }
};

export const ActiveEnvironmentName: string = (
    // Just so that we can use 'local' as an override without getting type errors
    (process.env.NODE_ENV === '_') ? 'development' :
        (process.env.VUE_APP_ACTIVE_ENVIRONMENT || process.env.NODE_ENV || 'production')
);

if (!ActiveEnvironmentName)
{
    alert('Error: HCAD instance configuration invalid.  Please notify a developer of this error.');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const activeEnvironment: typeof environments.development = (environments as any)[ActiveEnvironmentName];
if (ActiveEnvironmentName && !activeEnvironment)
{
    alert(`Error: HCAD instance configuration invalid for environment [${ActiveEnvironmentName}].  Please notify a developer of this error.`);
}
console.log(`process.env.VUE_APP_ACTIVE_ENVIRONMENT == ${process.env.VUE_APP_ACTIVE_ENVIRONMENT}`);
console.log(`Using environment ${ActiveEnvironmentName}, data below`);
console.log(activeEnvironment);
export default activeEnvironment;