








































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
    DashboardPageType,
    IndividualDataTableDashboardPage, 
    IndividualDataTableUserData, 
    FieldValueType,
    DashboardPageFieldType,
    DashboardPageFilterType,
    MetricsToggleQueryData,
    BaseFilterQueryData
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents, fieldViewerComponents } from '@/utils/typed-configs';
import dataSourceModule from '@/store/modules/DataSourceModule';
import dashModule from '@/store/modules/DashboardModule';
import { UserInfoAndMetrics, FilterValueType } from '@/models/hcad/shared/queries';
import RowRenderer from './IndividualDataTableRowRenderer.vue';

@Component({
    components: {
        RowRenderer
    }
})
export default class IndividualDataTable extends Vue
{
    @Prop({type: Object, required: true})
    page!: IndividualDataTableDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    @Prop({type: Array, required: true})
    desiredMetrics!: string[];

    searchText = '';

    sortBy = '0';
    sortDesc = true;

    itemsPerPage = 25;

    get footerProps()
    {
        return {
            'items-per-page-options': [10, 25, 50, 100],
        };
    }

    tableHeight()
    {
        return window.innerWidth > 878 ? 'calc(100vh - 275px)' : 'calc(100vh - 330px)';
    }

    onResize()
    {
        this.$forceUpdate();
    }

    @Watch('filterState', { deep: true })
    async onNewFilters()
    {
        await this.refresh();
    }

    customSort(items: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>][], sortBy: number[], sortDescTab: boolean[])
    {
        // Only support sorting with a single element
        if (sortBy.length != 1) return items;

        const sortDesc = sortDescTab[0];
        const fieldIdx = sortBy[0];
        const field = this.page.fields[fieldIdx];
        const sortFactory = fieldViewerComponents.getDataFactory(field.type);
        if (!sortFactory) return items;

        const sortFn = sortFactory();
        if (!sortFn) return items;

        items.sort((a, b)=>
        {
            const res = sortFn(fieldIdx, field.type, a, b);
            if (sortDesc)
            {
                return -res;
            }
            return res;
        });

        // console.log(sortBy);
        return items;
    }

    changeSort(column: number)
    {
        if (this.sortBy == `${column}`)
        {
            this.sortDesc = !this.sortDesc;
        }
        else
        {
            this.sortBy = `${column}`;
            this.sortDesc = true;
        }
    }

    get rowClass()
    {
        if (this.page.benchmarkPageIdx !== null) return 'idt-clickable-row';
        return '';
    }

    clickedTableRow(item: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>])
    {
        if (this.page.benchmarkPageIdx !== null)
        {
            const endpoint = `/dashboard/page/${this.page.benchmarkPageIdx}/${item[1].user.userid}`;
            this.$router.push(endpoint);
        }
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get filterValues(): FilterValueType[]
    {
        return this.filterState.map(fs => fs.toValue());
    }

    get anySelectedMetrics(): boolean
    {
        return this.filterState.some((fs) =>
            fs.type === DashboardPageFilterType.MetricsToggle
            && (fs as MetricsToggleQueryData).selectedMetrics
            && (fs as MetricsToggleQueryData).selectedMetrics.length);
    }

    queryResult: UserInfoAndMetrics<IndividualDataTableUserData>[] | null = null;

    loading = false;

    get tableHeaders()
    {
        const headers = [];
        for(let i = 0; i < this.page.fields.length; ++i)
        {
            const field = this.page.fields[i];
            if (!(this.anySelectedMetrics) && field.type === DashboardPageFieldType.MatchPercent)
            {
                continue;
            }
            const sortable = fieldViewerComponents.getDataFactory(field.type) != undefined;
            headers.push({
                text: field.name,
                value: i,
                sortable,
            });
        }
        return headers;
    }

    get tableData()
    {
        const data: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>][] = [];
        if (!this.queryResult) return data;
    
        for (const val of this.queryResult)
        {
            if (val.pageData)
            {
                const vdata: [DashboardPageFieldType, FieldValueType][] = [];
                for(let i = 0; i < this.page.fields.length; ++i)
                {
                    if (!(this.anySelectedMetrics) && this.page.fields[i].type === DashboardPageFieldType.MatchPercent)
                    {
                        continue;
                    }
                    vdata.push([this.page.fields[i].type, val.pageData.fieldValues[i]]);
                }
                data.push([vdata, val]);
            }
        }
        return data;
    }

    get filteredTableData()
    {
        const data = this.tableData;
        if (this.searchText.trim().length === 0) return data;

        const search = this.searchText.trim().toLowerCase();
        return this.tableData.filter(a=>
        {
            const user = a[1].user;
            return user.fullName.toLowerCase().includes(search) || user.email.toLowerCase().includes(search);
        });
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const data = await dataSourceModule.queryIndividualUserTablePage({
                dashboard: await dashModule.getActiveDashboard(),
                pageIdx: this.index,
                filterValues: this.filterValues
            });
            
            if (data)
            {
                this.$set(this, 'queryResult', data.data);
            }
            else
            {
                this.$set(this, 'queryResult', null);
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    async mounted()
    {
        await this.refresh();
    }

    created()
    {
        window.addEventListener("resize", this.onResize);
    }

    destroyed()
    {
        window.removeEventListener("resize", this.onResize);
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.IndividualDataTable, IndividualDataTable)
    .registerDataFactory(DashboardPageType.IndividualDataTable, ()=>new IndividualDataTableDashboardPage)
;
