






import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData, Dashboard } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents } from '../../../utils/typed-configs';
import CapabilityIcon from './CapabilityIcon.vue';

@Component({components: {CapabilityIcon}})
export default class CapabilitiesRenderer extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get capabilityList()
    {
        const res = [];
        for (const cat of this.dashboard.capabilities)
        {
            for (const cap of cat.capabilities)
            {
                res.push(cap);
                for (const subcap of cap.subCapabilities)
                {
                    subcap.sub = true;
                    res.push(subcap);
                }
            }
        }
        return res;
    }

    get metricValues()
    {
        return this.context.metrics.values;
    }
}

fieldViewerComponents.registerComponent(DashboardPageFieldType.Capabilities, CapabilitiesRenderer);
