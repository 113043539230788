





























































































import { DashboardPageFilterType, RoleBaselineDashboardPage } from '@/models/hcad/shared/dashboard';
import RoleBaseline from '@/models/hcad/shared/role-baseline';
import dashModule from '@/store/modules/DashboardModule';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

function isStringValid(str: string)
{
    return str && str.trim().length > 0;
}

@Component
export default class RoleBaselineModal extends Vue
{
    @Prop({type: Object, required: true})
    page!: RoleBaselineDashboardPage;

    @Prop({type: Boolean, default: false})
    readOnly!: boolean;

    @Prop({type: Object, required: false})
    target!: RoleBaseline;

    @Watch('target')
    onTargetChanged()
    {
        if (this.target)
        {
            this.baselineData = JSON.parse(JSON.stringify(this.target));
        }
    }

    baselineData: RoleBaseline = new RoleBaseline();

    submit()
    {
        if (!isStringValid(this.baselineData.name))
        {
            alert('Please enter a name for the role baseline');
            return;
        }
        this.$emit('created', this.baselineData);
    }

    async mounted()
    {
        if (this.target)
        {
            this.onTargetChanged();
        }

        const values = await dashModule.getAllDefaultFiltersForActiveDashboard();
        if (!values)
        {
            alert('Failed to load filter data');
            return;
        }

        this.departmentOptions = values[DashboardPageFilterType.DepartmentSelect].map(a=>a?.valueOf() as string);
        this.functionOptions = values[DashboardPageFilterType.FunctionSelect].map(a=>a?.valueOf() as string);
        this.jobCodeOptions = values[DashboardPageFilterType.JobCodeSelect].map(a=>a?.valueOf() as string);
        this.locationOptions = values[DashboardPageFilterType.LocationSelect].map(a=>a?.valueOf() as string);
    }

    departmentOptions: string[] = [];
    functionOptions: string[] = [];
    jobCodeOptions: string[] = [];
    locationOptions: string[] = [];
}

