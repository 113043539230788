




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerSorter } from '../../../utils/typed-configs';

@Component
export default class DateCompletedRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: string;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get realDate()
    {
        return new Date(this.value);
    }

    get localeDisplay()
    {
        return this.realDate.toLocaleString();
    }
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.DateCompleted, DateCompletedRenderer)
    .registerDataFactory(DashboardPageFieldType.DateCompleted, ()=>
        (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter)=>
        {
            // just go with it here...
            const aval = new Date(a[0][fieldIdx][1] as string);
            const bval = new Date(b[0][fieldIdx][1] as string);
            return (aval.getTime()) - (bval.getTime());
        });

