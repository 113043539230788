












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Capability, ValueColor } from '@/models/hcad/shared/dashboard';
import DynamicIcon from '@/components/DynamicIcon.vue';

@Component({components: {DynamicIcon}})
export default class CapabilityIcon extends Vue
{
    @Prop({type: Object, required: true})
    readonly capability!: Capability;

    @Prop({type: Number, required: true})
    readonly count!: number;

    @Prop({type: Number, required: true})
    readonly value!: number;

    bigSize = 50;

    get shouldRenderLarge()
    {
        return this.count <= 6;
    }

    get capIconStyle()
    {
        let style = `background-color: ${this.bgColor};`;
        if (this.shouldRenderLarge)
        {
            style += `width: ${this.bigSize}px; height: ${this.bigSize}px;`;
        }
        return style;
    }

    get iconStyle()
    {
        if (this.shouldRenderLarge)
        {
            return `width: ${this.bigSize}px; height: ${this.bigSize}px;`;
        }
        return "width: 80%; height: 80%;";
    }

    get valueColoration() : ValueColor | null
    {
        // console.log(this.capability);
        if (this.capability.valueColors && this.capability.valueColors.length)
        {
            const valueColorsCopy = [...this.capability.valueColors];
            valueColorsCopy.sort((v1, v2) =>
            {
                return v2.maxValue - v1.maxValue;
            });
            let vc = null;
            valueColorsCopy.forEach((v) =>
            {
                if (v.maxValue >= this.value)
                {
                    vc = v;
                }
            });
            return vc;
        }
        return null;
    }

    get capIconUrl()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.iconUrl;
        }
        return this.capability.iconUrl;
    }

    get capIconClass()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.iconClass;
        }
        return this.capability.iconClass;
    }

    get bgColor()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.color;
        }
        else if (this.value === 0)
        {
            return '#E0E0E0';
        }
        return this.capability.color;
    }

    get fgColor()
    {
        // if (this.value === 0) return 'rgba(255, 255, 255, 0.4)';
        return 'white';
    }
}

