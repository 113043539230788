
























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    DashboardPageRoleBaselineFilter,
    RoleBaselineQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';
import { RoleBaselineDefinitionValueType, RoleBaselineFilterValueType } from '@/models/hcad/shared/queries';
import dashModule from '../store/modules/DashboardModule';
import roleBaselineModule from '@/store/modules/RoleBaselineModule';
// import { FilterValueType } from '../models/hcad/shared/queries';

@Component({})
export default class FilterSelect extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageRoleBaselineFilter;

    @Prop({type: Object, required: true}) 
    value!: RoleBaselineQueryData;

    oldValue: RoleBaselineFilterValueType | null = null;

    selectedBaseline: string | null = null;

    @Watch('selectedBaseline')
    onSelectedBaselineChanged(newValue: string | null, oldValue: string | null)
    {
        if (newValue !== oldValue)
        {
            this.value.baseline.value = newValue;
        }
    }

    autoMode = 0;

    @Watch('autoMode')
    onAutoModeChange()
    {
        if (this.autoMode === 0)
        {
            this.value.isManual = false;

            const old = this.oldValue;
            this.oldValue = this.value.baseline;
            if (old)
            {
                this.value.baseline = old;
            }
            else
            {
                this.value.baseline = { manual: false, value: null };
            }
        }
        else
        {
            this.value.isManual = true;

            const old = this.oldValue;
            this.oldValue = this.value.baseline;
            if (old)
            {
                this.value.baseline = old;
            }
            else
            {
                this.value.baseline = { manual: true, value: {
                    department: '',
                    function: '',
                    jobCode: '',
                    location: '',
                    yearRange: [0, 20]
                } };
            }
        }
        this.$set(this.value, 'baseline', this.value.baseline);
    }

    get baselineLiteralData()
    {
        return this.value.baseline.value as RoleBaselineDefinitionValueType;
    }

    async mounted()
    {
        if (this.filter)
        {
            // TODO: Get baseline options

            const baselines = await roleBaselineModule.getAllRoleBaselinesInDashboard(await (await dashModule.getActiveDashboard())._id);
            const values = await dashModule.getAllDefaultFiltersForActiveDashboard();

            if (!values || !baselines)
            {
                alert('Failed to load baseline filter data');
                return;
            }

            this.baselineOptions = baselines.map(a => ({ text: a.name, value: a._id }));
            this.departmentOptions = values[DashboardPageFilterType.DepartmentSelect].map(a=>a?.valueOf() as string);
            this.functionOptions = values[DashboardPageFilterType.FunctionSelect].map(a=>a?.valueOf() as string);
            this.jobCodeOptions = values[DashboardPageFilterType.JobCodeSelect].map(a=>a?.valueOf() as string);
            this.locationOptions = values[DashboardPageFilterType.LocationSelect].map(a=>a?.valueOf() as string);
        }
    }

    baselineOptions: { text: string, value: string }[] = [];
    departmentOptions: string[] = [];
    functionOptions: string[] = [];
    jobCodeOptions: string[] = [];
    locationOptions: string[] = [];
}

filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.RoleBaseline, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.RoleBaseline,
        ()=>new RoleBaselineQueryData(DashboardPageFilterType.RoleBaseline))
;
