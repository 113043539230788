var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.page.name)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-card-text',{staticStyle:{"padding-bottom":"0"}},[_c('v-data-table',{ref:"datatable",staticStyle:{"width":"100%"},attrs:{"loading":_vm.loading,"disabled":_vm.loading,"items":_vm.filteredTableData,"headers":_vm.tableHeaders,"custom-sort":_vm.customSort,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"hide-default-header":"","fixed-header":"","height":_vm.tableHeight()},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header idt-header"},[_c('tr',_vm._l((headers),function(item){return _c('th',{key:item.value,staticClass:"column idt-header-cell",class:[item.sortable ? 'sortable' : '', _vm.sortBy == item.value ? 'active': '', _vm.sortDesc ? 'desc':'asc'],on:{"click":function($event){item.sortable ? _vm.changeSort(item.value) : ''}}},[_c('div',{staticStyle:{"display":"flex","flex-flow":"row nowrap","justify-content":"center"}},[_c('div',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.text))]),_c('div',{staticStyle:{"position":"absolute","bottom":"-2%","right":"1%"}},[(item.sortable)?_c('div',{staticClass:"v-data-table-header__icon",style:({
                                                opacity: (_vm.sortBy == item.value ? 1 : 0.3),
                                                transform: (_vm.sortBy == item.value ? undefined : 'rotate(180deg)'),
                                            })},[_c('v-icon',{staticClass:"idt-header-icon",attrs:{"small":""}},[_vm._v("mdi-triangle")])],1):_vm._e()])])])}),0)])]}},{key:"item",fn:function(ref){
                                            var item = ref.item;
                                            var index = ref.index;
return [_c('tr',{key:index,class:_vm.rowClass,on:{"click":function($event){return _vm.clickedTableRow(item)}}},_vm._l((item[0]),function(fval,idx){return _c('td',{key:idx,staticClass:"text-start",class:("" + (index == 0 ? 'idt-cell-first' : 'idt-cell'))},[_c('row-renderer',{attrs:{"type":fval[0],"value":fval[1],"context":item[1],"page":_vm.page,"dashboard":_vm.dashboard}})],1)}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }