






























import { Component, Vue, Prop } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    DashboardPageRangeFilter,
    RangeFilterQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';

@Component({})
export default class FilterRange extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageRangeFilter;

    @Prop({type: Object, required: true}) 
    value!: RangeFilterQueryData;

    onSelection(newValue: Array<number>)
    {
        if (newValue.length > 1)
        {
            this.value.selectedMin = newValue[0];
            this.value.selectedMax = newValue[1];
        }
    }
}

filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.YearsEmployedRange, FilterRange)
    .registerDataFactory(DashboardPageFilterType.YearsEmployedRange,
        ()=>new RangeFilterQueryData(DashboardPageFilterType.YearsEmployedRange, 0, 20))
;
