




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerSorter } from '../../../utils/typed-configs';

@Component
export default class UserNameRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;
}

fieldViewerComponents.
    registerComponent(DashboardPageFieldType.UserName, UserNameRenderer)
    .registerDataFactory(DashboardPageFieldType.UserName, ()=>
        (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter)=>
        {
            const aval = a[1].user.fullName;
            const bval = b[1].user.fullName;
            return -aval.localeCompare(bval);
        });

