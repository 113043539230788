








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';
import { Dashboard } from '../../models/hcad/shared/dashboard';

@Component
export default class HeaderBar extends Vue
{
    @Prop({ type: Object, default: ()=>[] })
    dashboard!: Dashboard | null;

    @Prop({ type: Boolean, default: false })
    hideTabs!: boolean;

    get shouldDisplayAdminButton()
    {
        return auth.isHcadAdmin;
    }

    get title()
    {
        return this.dashboard ? this.dashboard.environmentSettings.headerText : 'Dashboard';
    }

    get logo()
    {
        return ((this.dashboard && this.dashboard.environmentSettings.headerLogo.length > 0)
            ? this.dashboard.environmentSettings.headerLogo
            : null);
    }

    get pageList()
    {
        return this.dashboard ? this.dashboard.pages : [];
    }

    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    get userName()
    {
        return auth.activeUser ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}` : '';
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            // TODO: Load environment properties
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }

    async logout()
    {
        await auth.logout();
    }
}
