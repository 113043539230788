
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
    DashboardPageType,
    BaseFilterQueryData,
    RoleBaselineDashboardPage
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents } from '@/utils/typed-configs';
import RoleBaseline from '@/models/hcad/shared/role-baseline';
import roleBaselineModule from '@/store/modules/RoleBaselineModule';
import dashModule from '@/store/modules/DashboardModule';
import RoleBaselineEdit from './role-baseline-components/RoleBaselineEdit.vue';
import { hasAnyRoles } from '@/utils/role-util';
import authModule from '@/store/modules/AuthModule';

@Component({components: {RoleBaselineEdit}})
export default class RoleBaselinePage extends Vue
{
    @Prop({type: Object, required: true})
    page!: RoleBaselineDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    // unused
    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    // unused
    @Prop({type: Array, required: true})
    desiredMetrics!: string[];

    baselines: RoleBaseline[] = [];
    loading = false;

    createModalOpen = false;
    openCreateModal()
    {
        this.createModalOpen = true;
    }

    get canModifyBaselines()
    {
        if (!authModule.activeUser) return false;
        return hasAnyRoles(authModule.activeUser, ['developer', 'owner']);
    }

    editModalOpen = false;
    editTarget: RoleBaseline | null = null;

    async tryDelete(baseline: RoleBaseline)
    {
        const confirmed = confirm('Are you sure you want to delete this baseline?');
        if (confirmed)
        {
            await roleBaselineModule.deleteRoleBaseline(baseline._id);
            await this.refresh();
        }
    }

    @Watch('editTarget')
    onEditTargetChange(newVal: RoleBaseline | null)
    {
        if (newVal !== null)
        {
            this.editModalOpen = true;
            this.$nextTick(()=>
            {
                (this.$refs.baselineEdit as RoleBaselineEdit).onTargetChanged();
            });
        }
    }

    @Watch('editModalOpen')
    onEditModalOpenChanged(newVal: boolean)
    {
        if (!newVal)
        {
            this.editTarget = null;
        }
    }

    async onModalCreateEvent(created: RoleBaseline)
    {
        try
        {
            await roleBaselineModule.createRoleBaseline({ dashboardId: ((await dashModule.getActiveDashboard())._id) , baseline: created });
            this.createModalOpen = false;
            await this.refresh();
        }
        catch(err)
        {
            alert('Failed to create role baseline.  Please check browser console for errors.');
            console.error(err);
        }
    }

    async onModalEditEvent(updated: RoleBaseline)
    {
        try
        {
            await roleBaselineModule.updateRoleBaseline(updated);
            this.editTarget = null;
            await this.refresh();
        }
        catch(err)
        {
            alert('Failed to create role baseline.  Please check browser console for errors.');
            console.error(err);
        }
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const res = await roleBaselineModule.getAllRoleBaselinesInDashboard(await (await dashModule.getActiveDashboard())._id);
            if (res)
            {
                this.baselines = res;
            }
        }
        catch(er)
        {
            alert('Error loading baselines.');
        }
        this.loading = false;
    }

    async mounted()
    {
        await this.refresh();
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.RoleBaselinePage, RoleBaselinePage)
    .registerDataFactory(DashboardPageType.RoleBaselinePage, ()=>new RoleBaselineDashboardPage)
;
