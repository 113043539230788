































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageSelectFilter, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { filterEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';

@Component
export default class SelectConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageSelectFilter;
    
    keyOffset = 0;

    createOption()
    {
        this.filter.options.push('');
        ++this.keyOffset;
    }

    deleteOption(idx: number)
    {
        this.filter.options.splice(idx, 1);
        ++this.keyOffset;
    }

    swapOptions(a: number, b: number)
    {
        const bval = this.filter.options[b];
        this.filter.options[b] = this.filter.options[a];
        this.filter.options[a] = bval;
        ++this.keyOffset;
    }

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    validate()
    {
        return validateRefs(this.$refs);
    }
}

filterEditorComponents
    .registerComponent(DashboardPageFilterType.LocationSelect, SelectConfigEdit)
    .registerDataFactory(DashboardPageFilterType.LocationSelect, ()=>new DashboardPageSelectFilter(DashboardPageFilterType.LocationSelect, [], 'Location'))
;
filterEditorComponents
    .registerComponent(DashboardPageFilterType.DepartmentSelect, SelectConfigEdit)
    .registerDataFactory(DashboardPageFilterType.DepartmentSelect, ()=>new DashboardPageSelectFilter(DashboardPageFilterType.DepartmentSelect, [], 'Department'))
;
filterEditorComponents
    .registerComponent(DashboardPageFilterType.FunctionSelect, SelectConfigEdit)
    .registerDataFactory(DashboardPageFilterType.FunctionSelect, ()=>new DashboardPageSelectFilter(DashboardPageFilterType.FunctionSelect, [], 'Function'))
;
filterEditorComponents
    .registerComponent(DashboardPageFilterType.JobCodeSelect, SelectConfigEdit)
    .registerDataFactory(DashboardPageFilterType.JobCodeSelect, ()=>new DashboardPageSelectFilter(DashboardPageFilterType.JobCodeSelect, [], 'Job Code'))
;
filterEditorComponents
    .registerComponent(DashboardPageFilterType.CompanySelect, SelectConfigEdit)
    .registerDataFactory(DashboardPageFilterType.CompanySelect, ()=>new DashboardPageSelectFilter(DashboardPageFilterType.CompanySelect, [], 'Company'))
;
