import * as Axios from 'axios';
import env from './Environment';
import auth from '@/store/modules/AuthModule';

export const options: Axios.AxiosRequestConfig = {
    baseURL: env.API,
};

export const postProcessInstance = (axios: Axios.AxiosInstance) => 
{
    axios.interceptors.request.use((cfg) => 
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((cfg as any).ignoreAdditionalHeaders) return cfg;
        if (auth.authToken) 
        {
            cfg.headers['x-auth'] = auth.authToken;
        }
        return cfg;
    });

    axios.interceptors.response.use((response) => 
    {
        if (response.headers['x-auth']) 
        {
            auth.setAuthToken(response.headers['x-auth']);
        }
        return response;
    });
};