




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData, BaseDashboardPage, Dashboard } from '@/models/hcad/shared/dashboard';
import { fieldViewerComponents, FieldViewerSorter } from '@/utils/typed-configs';
import { UserInfoAndMetrics } from '../../models/hcad/shared/queries';

// TODO: Automate this...?
require('./individual-data-table-renderers/CapabilitiesRenderer.vue');
require('./individual-data-table-renderers/DateCompletedRenderer.vue');
require('./individual-data-table-renderers/MatchPercentRenderer.vue');
require('./individual-data-table-renderers/TimeInOrgRenderer.vue');
require('./individual-data-table-renderers/UserEmailRenderer.vue');
require('./individual-data-table-renderers/UserNameRenderer.vue');

const defaultStringSortFactory =()=>
    (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerSorter, b: FieldViewerSorter)=>
    {
        // just go with it here...
        const aval = a[0][fieldIdx][1] as string;
        const bval = b[0][fieldIdx][1] as string;
        return -aval.localeCompare(bval);
    };

// Default sorters
fieldViewerComponents
    .registerDataFactory(DashboardPageFieldType.Location, defaultStringSortFactory)
    .registerDataFactory(DashboardPageFieldType.JobCode, defaultStringSortFactory)
    .registerDataFactory(DashboardPageFieldType.Department, defaultStringSortFactory)
    .registerDataFactory(DashboardPageFieldType.Function, defaultStringSortFactory)
;

@Component
export default class IndividualDataTableRowRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    @Prop({type: Object, required: true})
    page!: BaseDashboardPage;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    get component()
    {
        return fieldViewerComponents.getComponent(this.type);
    }
}
